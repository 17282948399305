import React from 'react';
import { Box, Typography, Chip, Container } from '@mui/material';

const industries = [
    'Finance & Banking',
    'Healthcare & Insurance',
    'Education & E-learning',
    'Retail & E-commerce',
    'Government & Public Sector',
    'IT & Cloud Services',
    'Telecommunications',
    'Media & Entertainment',
    'Energy & Utilities',
    'Manufacturing & Supply Chain',
    'Legal & Compliance',
    'Hospitality & Travel',
    'Nonprofits & NGOs',
    'Marketing & Advertising',
    'Real Estate & Property Management',
];

function Industries() {
    return (
        <Box sx={{ pt: 6, pb: 0, bgcolor: 'background.paper' }}>
            <Container maxWidth="lg">
                {/* Section Header */}
                <Typography
                    variant="h4"
                    component="h2"
                    align="center"
                    sx={{ fontWeight: '600', mb: 2 }}
                >
                    Phishing Simulations for Every Industry
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: 'text.secondary', mb: 6 }}
                >
                    PhishIntel empowers industries with tailored phishing simulations, enhancing security awareness and resilience across diverse sectors.
                </Typography>

                {/* Industry Chips */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    {industries.map((industry, index) => (
                        <Chip
                            key={index}
                            label={industry}
                            variant="outlined"
                            sx={{
                                fontWeight: 'medium',
                                color: 'text.primary',
                            }}
                        />
                    ))}
                </Box>
            </Container>
        </Box>
    );
}

export default Industries;
