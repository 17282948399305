import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                ...theme.applyStyles('dark', {
                    backgroundImage:
                        'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
                }),
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 12,
                    pb: 0
                }}
            >
                <Stack
                    spacing={2}
                    sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                            fontWeight: '500'
                        }}
                    >
                        AI-Powered&nbsp;
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                fontSize: 'inherit',
                                color: 'primary.main',
                                fontWeight: '500'
                            }}
                        >
                            Simulation
                        </Typography>
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: 'text.secondary',
                            width: { sm: '100%', md: '80%' },
                        }}
                    >
                        Simulate real-world phishing attacks and empower your organization to
                        identify threats, train users, and minimize security risks with PhishIntel.
                    </Typography>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            href="https://github.com/cloudsecnetwork/phishintel"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Github Repo
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            href="https://hub.docker.com/r/cloudsecnetwork/phishintel"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Documentation
                        </Button>
                    </Stack>

                </Stack>
            </Container>
        </Box>
    );
}
