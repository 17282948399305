import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import Header from '../../../src/components/Header';
import Footer from '../../../src/components/Footer';

const NotFoundPage = () => {
    return (
        <Box>
            <Header />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60vh',
                    textAlign: 'center',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    p: 4,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: 'clamp(3rem, 10vw, 5rem)',
                            fontWeight: 'bold',
                            mb: 2,
                            color: 'primary.main',
                        }}
                    >
                        404
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{ mb: 3, color: 'text.secondary' }}
                    >
                        Oops! Page not Found.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ mb: 4, color: 'text.secondary' }}
                    >
                        Uh-oh! This page seems to have been phished away.
                        Don’t worry, you’re still safe here. Let’s guide you back to familiar territory.
                    </Typography>
                    <Button
                        component={Link}
                        to="/"
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                        }}
                    >
                        Back to Home
                    </Button>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default NotFoundPage;
