import React from 'react';
import { Box, Container, Typography, Grid, Link, Stack, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter'; // Updated Twitter icon
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer() {
  return (
    <Box
      sx={{
        bgcolor: 'grey.100',
        color: 'text.primary',
        py: 4,
        mt: 4,
        width: '100%',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Us */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              About PhishIntel
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              PhishIntel is an open-source phishing simulation tool designed to enhance
              cybersecurity awareness. Built by CloudSec Network, it empowers organizations
              to mitigate risks through realistic phishing campaigns.
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link
              href="#features"
              color="inherit"
              variant="body2"
              sx={{
                display: 'block',
                mb: 1,
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Features
            </Link>
            <Link
              href="#faq"
              color="inherit"
              variant="body2"
              sx={{
                display: 'block',
                mb: 1,
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              FAQs
            </Link>
            <Link
              href="https://hub.docker.com/r/cloudsecnetwork/phishintel"
              color="inherit"
              variant="body2"
              sx={{
                display: 'block',
                mb: 1,
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation
            </Link>
            <Link
              href="https://cloudsecnetwork.com/blog/"
              color="inherit"
              variant="body2"
              sx={{
                display: 'block',
                mb: 1,
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </Link>
            
          </Grid>

          {/* Connect With Us */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
              <IconButton href="https://github.com/cloudsecnetwork" color="inherit" target="_blank" rel="noopener noreferrer" size="small">
                <GitHubIcon />
              </IconButton>
              <IconButton href="https://linkedin.com/company/cloudsecnetwork" color="inherit" target="_blank" rel="noopener noreferrer" size="small">
                <LinkedInIcon />
              </IconButton>
              <IconButton href="https://x.com/cloudsecnetwork" color="inherit" target="_blank" rel="noopener noreferrer" size="small">
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://youtube.com/@cloudsecnetwork" color="inherit" target="_blank" rel="noopener noreferrer" size="small">
                <YouTubeIcon />
              </IconButton>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              Email: <Link href="mailto:hello@cloudsecnetwork.com" color="inherit">hello@cloudsecnetwork.com</Link>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Phone: +1-214-329-9755
            </Typography>
          </Grid>
        </Grid>

        {/* Bottom Section */}
        <Typography
          variant="body2"
          align="center"
          sx={{
            mt: 4,
            color: 'text.secondary',
            borderTop: '1px solid',
            borderColor: 'divider',
            pt: 2,
          }}
        >
          © {new Date().getFullYear()} PhishIntel. A product of{' '}
          <Link href="https://cloudsecnetwork.com" color="inherit" target="_blank" rel="noopener noreferrer">
            CloudSec Network
          </Link>. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
