import React from 'react';
import { Box } from '@mui/material';
import Hero from './Hero';
// import LogoCollection from './LogoCollection';
import Features from './Features';
import Industries from './Industries';
import FAQ from './FAQ'; 
import Header from '../../../src/components/Header';
import Footer from '../../../src/components/Footer';

function Home() {
    return (
        <Box>
            <Header />
            <Hero />
            {/* <LogoCollection /> */}
            <Features />
            <Industries />
            <FAQ />
            {/* Add other sections like Features, Testimonials, etc. */}
            <Footer />
        </Box>
    );
}

export default Home;
